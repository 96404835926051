/*
 * @Author: wuzhen wz0309@163.com
 * @Date: 2024-01-31 16:12:10
 * @LastEditors: lwk
 * @LastEditTime: 2024-02-06 10:45:10
 * @FilePath: \eiiplat_front\src\api\message.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from "@/utils/request";

// 获取所有信息
export function getMsgList(params) {
    return request({
        url: "/system/noticeSend/list",
        method: "get",
        params
    })
}

// 根据ID查询站内消息详情
export function getMsgInfo(params) {
    return request({
        url: "/system/noticeSend/get/" + params,
        method: "get",
    })
}

//   发送通知通告
export function sendMsg(data,ids) {
    return request({
        url: "/system/noticeSend/sendNotification?receiverIds=" + ids,
        method: "post",
        data
    })
}

//   读取消息
export function readMsg(params) {
    return request({
        url: "/system/noticeSend/read",
        method: "post",
        params
    })
}

//   读取所有消息
export function readAllMsg() {
    return request({
        url: "/system/noticeSend/readAll",
        method: "post",
    })
}

//   获取已读/未读
export function getMsgRead(params) {
    return request({
        url: "/system/noticeSend/count",
        method: "get",
        params
    })
}

// 删除
export function removeMsg(params) {
    return request({
        url: "/system/noticeSend/remove",
        method: "post",
        data:params
    })
}

// 修改
export function updateMsg(data) {
    return request({
        url: "/system/noticeSend/update",
        method: "post",
        data
    })
}