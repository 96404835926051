// 3. 在 main.js 中引入 Element UI 和懒加载
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueLazyload from 'vue-lazyload';
import VueCompositionAPI from "@vue/composition-api";
import axios from "axios";
// import echarts from 'echarts'
import store from "./store";
import './assets/styles/variables.scss';

Vue.use(ElementUI);
Vue.use(VueCompositionAPI);
Vue.use(VueLazyload, {
  loading: require('@/assets/loading.gif'), // 设置加载中图片
  error: require('@/assets/images/error.png') // 设置加载失败图片
});

// Vue.prototype.$axios = axios;

router.beforeEach((to, from, next) => {
  // 解决 Vue 页面跳转后不回到顶部的问题，适配所有主流浏览器。
  // chrome兼容
  document.body.scrollTop = 0;
  // firefox兼容
  document.documentElement.scrollTop = 0;
  // safari兼容

  window.pageYOffset = 0;
  /* 路由发生变化修改页面meta */
  next();
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
