import Vue from "vue";
import Router from "vue-router";

import Home from "@/views/Home";

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err);
};

Vue.use(Router);

export default new Router({
    mode: "history", //去掉url中的#
    routes: [
        {
            path: "/",
            name: "Home",
            component: ()=>import('@/views/Home.vue'),
            meta: {
                title: "青岛天人环境股份有限公司——值得信赖的环境产业平台服务商",
                content: {
                    keywords:
                        "天人环境,环境工程,环境规划,环境规划公司,餐厨（厨余）垃圾智能化升级改造、智控运维平台、集团管控、全产业链可追溯、设备智能化升级、自控集成、有机固废数字化转型、企业智能化转型、标识解析、二级节点、大数据平台、E平台、环境产业平台、环卫平台",
                    description:
                        "青岛天人股份有限公司成立于1999年，主要服务于环境产业，提供餐厨（厨余）垃圾智能化升级改造、智控运维平台、集团管控、全产业链可追溯、设备智能化升级、自控集成、有机固废数字化转型、企业智能化转型、标识解析、二级节点、大数据平台、E平台、环境产业平台、环卫平台等相关的产品及服务。"
                }
            }
        },
        {
            path: "/Sankyo",
            name: "Sankyo",
            component: () => import('@/views/Sankyo.vue'),
            meta: {
                title: "“三共”新模式",
                content: {
                    keywords:
                        "青岛天人环境股份有限公司——值得信赖的环境产业平台服务商",
                    description:
                        "青岛天人股份有限公司成立于1999年，主要服务于环境产业，提供餐厨（厨余）垃圾智能化升级改造、智控运维平台、集团管控、全产业链可追溯、设备智能化升级、自控集成、有机固废数字化转型、企业智能化转型、标识解析、二级节点、大数据平台、E平台、环境产业平台、环卫平台等相关的产品及服务。"
                }
            }
        },
        {
            path: "/AboutUs/ResearchAndDevelopmentStrength",
            name: "ResearchAndDevelopmentStrength",
            component: () => import('@/views/AboutUs/ResearchAndDevelopmentStrength.vue'),
            meta: {
                title: "研发实力",
            }
        },
        {
            path: "/AboutUs/TeamStyle",
            name: "TeamStyle",
            component: () => import('@/views/AboutUs/TeamStyle.vue'),
            meta: {
                title: "团队风采",
            }
        },
        {
            path: "/AboutUs/Introduction",
            name: "Introduction",
            component: () => import('@/views/AboutUs/Introduction.vue'),
            meta: {
                title: "公司介绍",
            }
        },
        {
            path: "/AboutUs/JoinUs",
            name: "JoinUs",
            component: () => import('@/views/AboutUs/JoinUs.vue'),
            meta: {
                title: "加入我们",
            }
        },

        // 核心业务
        {
            path: "/core/IntelligentControlPlatform",
            name: "IntelligentControlPlatform",
            component: () => import('@/views/core/IntelligentControlPlatform.vue'),
            meta: {
                title: "智控运维平台",
            }
        },
        {
            path: "/core/GroupControl",
            name: "GroupControl",
            component: () => import('@/views/core/GroupControl.vue'),
            meta: {
                title: "集团管控",
            }
        },
        {
            path: "/core/Traceability",
            name: "Traceability",
            component: () => import('@/views/core/Traceability.vue'),
            meta: {
                title: "全产业链可追溯",
            }
        },
        {
            path: "/core/EquipmentUpgrade",
            name: "EquipmentUpgrade",
            component: () => import('@/views/core/EquipmentUpgrade.vue'),
            meta: {
                title: "设备智能化升级",
            }
        },
        {
            path: "/core/AutonomousIntegration",
            name: "AutonomousIntegration",
            component: () => import('@/views/core/AutonomousIntegration.vue'),
            meta: {
                title: "自控集成",
            }
        },
        // 典型案例
        {
            path: "/TypicalExample/WanTaiYunHe",
            name: "WanTaiYunHe",
            component: () => import('@/views/TypicalExample/WanTaiYunHe.vue'),
            meta: {
                title: "万泰云和智控运维平台",
            }
        },
        {
            path: "/TypicalExample/FeiXiZhiKong",
            name: "FeiXiZhiKong",
            component: () => import('@/views/TypicalExample/FeiXiZhiKong.vue'),
            meta: {
                title: "中节能肥西智控运维管理平台",
            }
        },
        {
            path: "/TypicalExample/GanSuZhongGong",
            name: "GanSuZhongGong",
            component: () => import('@/views/TypicalExample/GanSuZhongGong.vue'),
            meta: {
                title: "甘肃重工集团“一网统管”项目",
            }
        },
        {
            path: "/TypicalExample/ShanDongShuiLiJu",
            name: "ShanDongShuiLiJu",
            component: () => import('@/views/TypicalExample/ShanDongShuiLiJu.vue'),
            meta: {
                title: "山东省水利局集团管控中心平台",
            }
        },
        {
            path: "/TypicalExample/MouHuaGongJiTuan",
            name: "MouHuaGongJiTuan",
            component: () => import('@/views/TypicalExample/MouHuaGongJiTuan.vue'),
            meta: {
                title: "某化工集团废弃物全生命周期管理数字化升级",
            }
        },
        {
            path: "/TypicalExample/XiHaiAn",
            name: "XiHaiAn",
            component: () => import('@/views/TypicalExample/XiHaiAn.vue'),
            meta: {
                title: "西海岸城市管理一网统管平台",
            }
        },
        {
            path: "/TypicalExample/FuYangXunHuan",
            name: "FuYangXunHuan",
            component: () => import('@/views/TypicalExample/FuYangXunHuan.vue'),
            meta: {
                title: "富阳区循环经济产业园餐厨垃圾处置项目自控集成",
            }
        },
        {
            path: "/TypicalExample/ShengYuanHuanBao",
            name: "ShengYuanHuanBao",
            component: () => import('@/views/TypicalExample/ShengYuanHuanBao.vue'),
            meta: {
                title: "圣元环保餐厨垃圾资源化处理厂项目自控集成",
            }
        },
        {
            path: "/TypicalExample/FeiXiZhiNengHua",
            name: "FeiXiZhiNengHua",
            component: () => import('@/views/TypicalExample/FeiXiZhiNengHua.vue'),
            meta: {
                title: "肥西智能化升级项目",
            }
        },
        {
            path: "/TypicalExample/FuYangCanChu",
            name: "FuYangCanChu",
            component: () => import('@/views/TypicalExample/FuYangCanChu.vue'),
            meta: {
                title: "富阳餐厨项目智控运维平台",
            }
        },
    ]
});
