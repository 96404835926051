/*
 * @Author: lwk
 * @Date: 2024-01-24 08:49:13
 * @LastEditors: lwk
 * @LastEditTime: 2024-02-06 15:38:18
 * @Description: 请填写简介
 */
import Vue from "vue";
import Vuex from "vuex";
import getters from "./getters";

import user from "./modules/user";
import app from './modules/app';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    app,
    // settings,
    user
  },
  getters
});

export default store;
