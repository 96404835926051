<template>
  <div class="nav-container">
    <div class="logoWrap">
      <img src="@/assets/images/logo.png">
    </div>
    <div class="nav-bar">
      <!-- 普通导航项 -->
      <div
          class="nav-item"
          :class="{ active: activeMenu === '/' }"
          @click="navigateTo('/')"
      >首页
      </div>

      <!-- 简单下拉菜单 -->
      <div
          class="nav-item dropdown"
          :class="{ active: activeMenu.startsWith('/core') }"
          @mouseenter="showSimpleMenu = true"
          @mouseleave="showSimpleMenu = false"
      >
        核心业务
        <transition name="el-zoom-in-top">
          <div class="dropdown-menu simple-menu" v-if="showSimpleMenu">
            <div
                class="menu-item"
                v-for="(item, index) in simpleMenuItems"
                :key="index"
                :class="{ selected: activeMenu === item.path }"
                @click="navigateTo(item.path)"
            >
              {{ item.name }}
            </div>
          </div>
        </transition>
      </div>

      <!-- 复杂下拉菜单 -->
      <div
          class="nav-item dropdown"
          :class="{ active: activeMenu.startsWith('/TypicalExample') }"
          @mouseenter="showComplexMenu = true"
          @mouseleave="showComplexMenu = false"
      >
        典型案例
        <transition name="el-zoom-in-top" v-if="showComplexMenu">
          <div class="dropdown-menu complex-menu">
            <div class="left-menu">
              <div class="left-menu-container">
                <div
                    v-for="(item, index) in leftMenuItems"
                    :key="index"
                    :class="{ active: selectedLeftMenu === index }"
                    @click="selectLeftMenu(index)"
                    class="left-menu-item"
                >
                  {{ item.name }}
                </div>
              </div>
            </div>
            <div class="right-menu">
              <div
                  v-for="(sub, idx) in selectedRightMenuItems"
                  :key="idx"
                  :class="{ active: activeMenu === sub.path }"
                  class="right-menu-item"
                  @click="navigateTo(sub.path)"
              >
                <div class="menu-item-title">{{ sub.title }}</div>
                <div class="menu-item-description">{{ sub.description }}</div>
              </div>
            </div>
          </div>
        </transition>
      </div>

      <div
          class="nav-item"
          :class="{ active: activeMenu === '/Sankyo' }"
          @click="navigateTo('/Sankyo')"
      >“三共”新模式
      </div>

      <!-- 关于我们 下拉菜单 -->
      <div
          class="nav-item dropdown"
          :class="{ active: activeMenu.startsWith('/AboutUs') }"
          @mouseenter="showAboutMenu = true"
          @mouseleave="showAboutMenu = false"
      >
        关于我们
        <transition name="el-zoom-in-top">
          <div class="dropdown-menu simple-menu" v-if="showAboutMenu">
            <div
                class="menu-item"
                v-for="(item, index) in aboutMenuItems"
                :key="index"
                :class="{ selected: activeMenu === item.path }"
                @click="navigateTo(item.path)"
            >
              {{ item.name }}
            </div>
          </div>
        </transition>
      </div>
    </div>
    <div class="navigateWrap">
      <!-- 导航栏 -->
      <div class="nav-item" @click="toggleQuickNav">
        <img src="../../assets/images/index/vector.png" alt="快速导航" class="quick-nav-icon"/>
        快速导航
      </div>
      <div class="nav-item" @click="handlePathTo('https://www.eiiplat.com')">Eiiplat 平台</div>
      <div class="nav-auth"  @click="handlePathTo('https://www.eiiplat.com/login')">
        <span class="login">登录</span> / <span class="register">注册</span>
      </div>

      <!-- 快速导航下拉框 -->
      <transition name="fade">
        <div v-if="showQuickNav" class="quick-nav">
          <div class="quick-nav-header">
            <span>快速导航</span>
            <button @click="showQuickNav = false">✖</button>
          </div>
          <div class="quick-nav-buttons">
            <button v-for="(item, index) in quickNavItems" :key="index" @click="navigateTo(item.path)">
              {{ item.label }}
            </button>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showSimpleMenu: false,
      showComplexMenu: false,
      showAboutMenu: false,
      activeMenu: "", // 当前选中的路由
      selectedLeftMenu: 0, // 默认选中左侧第一个选项
      simpleMenuItems: [
        {name: "智控运维平台", path: "/core/IntelligentControlPlatform"},
        {name: "集团管控", path: "/core/GroupControl"},
        {name: "全产业链可追溯", path: "/core/Traceability"},
        {name: "设备智能化升级", path: "/core/EquipmentUpgrade"},
        {name: "自控集成", path: "/core/AutonomousIntegration"},
      ],
      leftMenuItems: [
        {
          name: "智控运维平台",
          subItems: [
            {
              title: "万泰云和智控运维平台",
              description: "生活垃圾分选200t/d的垃圾资源化综合处置中心，处理工艺：原生生活垃圾通过输送机进入双轴撕碎机破碎或破袋，然后进入滚筒筛进行筛分，去除渣土等不可燃无机物。",
              path: "/TypicalExample/WanTaiYunHe"
            },
            {
              title: "中节能肥西智控运维管理平台",
              description: '肥西餐厨项目处理规模为800t/d，其中餐饮垃圾与厨余垃圾各400t/d。餐饮垃圾预处理采用“物料接收+大物质分拣+精分制浆+除砂除杂+油水分离”工艺。',
              path: "/TypicalExample/FeiXiZhiKong"
            },
          ]
        },
        {
          name: "集团管控",
          subItems: [
            {
              title: "甘肃重工集团“一网统管”项目",
              description: '本项目通过构建智能化、数字化的一网统管平台，联结打通集团MES、金蝶ERP、EHR、海康SCADA、库存管理等系统，以"线上化统一生产管控+智能运维平台+数据驱动决策"为核心路径，整合物联网、AI算法、标识解析等技术手段，系统性解决了集团信息化系统多，数据杂乱，数据模型不统一等造成的降本增效无方向、没有决策依据的问题。项目预计可实现集团总部对全产业链100%在线管控，生产效率提升15%-50%，人力成本降低30%-50%，总体增效20%以上。',
              path: "/TypicalExample/GanSuZhongGong"
            },
            {
              title: "山东省水利局集团管控中心平台",
              description: '通过构建集团级数字化管控平台，联结打通下属餐厨单位的采销系统、浪潮ERP等信息化系统，并运用物联网技术联通其共200余个摄像头，热能、电能、气能设备等智能化设备共计100多台，将采集的数据进行统一治理和智能化分析，利用可视化BI技术进行一网统管，系统性解决了集团下属餐厨项目数据分散、管理效能低下、运营决策缺乏数据支撑的痛点。平台对所属餐厨项目生产数据实时汇聚，构建了覆盖生产效益、产能达成、能耗监测、设备预警四大维度的智能分析体系，通过对各餐厨厂的信息收集与融合，可视化大屏及多维度数据看板，使集团运营效率提升30%以上，辅助管理者制定精准的产能调配方案和节能优化策略。',
              path: "/TypicalExample/ShanDongShuiLiJu"
            },
          ]
        },
        {
          name: "全产业链可追溯",
          subItems: [
            {
              title: "某化工集团废弃物全生命周期管理数字化升级",
              description: '通过部署全产业链可追溯平台，接入标识系统，利用“一物一码”对废电池、废原材料容器桶进行编码，使用区块链技术对生产、流通、回收全链条的数据流转做交接记录单，构建危废全生命周期管理体系，解决了化工行业长期存在的数据孤岛、合规成本高、资源利用率低、责任追溯难等核心问题。将之前的数据录入新平台后，利用AI大模型进行训练和违规操作进行智能分析预判，实现了危废全流程可追溯，环保处罚归零，合规成本降低60%，危废资源化利用率从28%提升至75%，综合处理成本下降25%，危废非法倾倒事件归零。',
              path: "/TypicalExample/MouHuaGongJiTuan"
            },
            {
              title: "西海岸城市管理一网统管平台",
              description: '青岛西海岸城市管理一网统管项目利用物联网、标识解析、区块链、大数据、云计算等技术，通过标识解析平台、大数据平台和可视化监管大屏，完成60多个子系统的数据汇集和治理，上下贯通市平台、区平台、企业系统，横向打通垃圾分类、环卫、供热、燃气、供水、排水六个业务板块，实现监管数据的秒级更新和各业务板块的监管。',
              path: "/TypicalExample/XiHaiAn"
            },
          ]
        },
        {
          name: "设备智能化升级",
          subItems: [
            {
              title: "肥西智能化升级项目",
              description: '肥西餐厨项目处理规模为800t/d，其中餐饮垃圾与厨余垃圾各400t/d。餐饮垃圾预处理采用“物料接收+大物质分拣+精分制浆+除砂除杂+油水分离”工艺。厨余垃圾预处理采用“物料接收+人工分选+粗破碎+筛选+细破碎+挤压脱水”工艺。对关键设备增加传感器监控设备的使用情况，对关键运行指标增加仪表采集数据供运维平台决策，通过设备诊断进行设备预防性维修，实现了非计划停机率0%，提升生产效益10%，通过对数据分析实现工艺运行优化，降低运行成本10%。',
              path: "/TypicalExample/FeiXiZhiNengHua"
            },
            {
              title: "富阳餐厨项目智控运维平台",
              description: '富阳餐厨智能工厂，国家十三五规划项目、浙江省重点示范项目，位于杭州市富阳区，采用平台运维模式，目前持续优化达成吨生物质综合处理增效20%左右。处理能力：设计餐厨150t/d+厨余150t/d，实际生产量达450t/d以上，扩容50%（设备升级与平台运维）；工艺：预处理+厌氧发酵+固分+沼气脱硫+沼气发电+污水处理+除臭。处理效果：100%数据线上化（控制），生产效益提升10%以上（投入与产出），管理效率提升30%以上（无纸化管理），设备0次非计划停机（处理量不允许）。人员架构：常规计划人员57人，实际38人，其中生产人员11人，巡检维保人员9人，人员降本34%。厂区安全：2020年10月投产至目前，从未出现过任何安全事故。',
              path: "/TypicalExample/FuYangCanChu"
            },
          ]
        },
        {
          name: "自控集成",
          subItems: [
            {
              title: "富阳区循环经济产业园餐厨垃圾处置项目自控集成",
              description: '本工程在管理用房内设置中央控制系统，现场由4个现场控制站及一个地衡控制系统组成。其中中央控制室为中央监控级，车间控制室为车间控制级，现场控制站组成现场控制级，位于现场的在线检测仪表和现场执行设备组成检测执行级，全面实现了餐厨垃圾处理过程的自动化化与高效化目标，确保处理全流程的精准控制与实时监控，通过自控集成管理，实现了“全流程监控、全要素管理、全链条协同”，确保了餐厨垃圾处理的环保性和安全性，达成了“零事故、零污染、零排放”的绿色运营目标。',
              path: "/TypicalExample/FuYangXunHuan"
            },
            {
              title: "圣元环保餐厨垃圾资源化处理厂项目自控集成",
              description: '在预处理车间单独建立餐厨垃圾预处理系统控制室，通过工业以太网光缆环网（TCP/IP方式）与整体监控系统通讯，控制系统预留和监控大屏的接口，重要画面和参数可在大屏上显示系统，PLC控制柜布置在控制室内或运行设备周围，实现了对餐厨垃圾处理全流程的自动化管控，达成了对处理效率和资源化利用率的显著提升预期。该系统实现了对垃圾处理各环节关键指标的实时监控与数据分析，确保处理过程的安全性和环保合规性，精准调控设备运行参数，提升了整体运营效率，达成了环保监管、资源循环利用和降本增效的多重目标，为餐厨垃圾处理的可持续发展提供了有力支撑。',
              path: "/TypicalExample/ShengYuanHuanBao"
            },
          ]
        },
      ],
      aboutMenuItems: [
        {name: "公司介绍", path: "/AboutUs/Introduction"},
        {name: "研发实力", path: "/AboutUs/ResearchAndDevelopmentStrength"},
        // {name: "团队风采", path: "/AboutUs/TeamStyle"},  // 暂时不展示'团队风采'页面
        {name: "加入我们", path: "/AboutUs/JoinUs"},
      ],

      // 右侧快捷导航
      showQuickNav: false,
      quickNavItems: [
        {label: "运维", path: "/core/IntelligentControlPlatform"},
        {label: "维保", path: "/core/IntelligentControlPlatform"},
        {label: "集团管控", path: "/core/GroupControl"},
        {label: "全产业链", path: "/core/Traceability"},
        {label: "自控", path: "/core/AutonomousIntegration"},
        {label: "升级", path: "/core/EquipmentUpgrade"},
        {label: "智能化", path: "/core/EquipmentUpgrade"},
        {label: "厌氧", path: "/TypicalExample/FuYangCanChu"},
        {label: "预处理", path: "/TypicalExample/FeiXiZhiKong"},
        {label: "脱硫", path: "/TypicalExample/FuYangCanChu"},
        {label: "污水", path: "/TypicalExample/FuYangCanChu"},
        {label: "除臭", path: "/TypicalExample/FuYangCanChu"},
      ],
    };
  },
  computed: {
    // 计算当前选中左侧菜单对应的右侧菜单项
    selectedRightMenuItems() {
      return this.leftMenuItems[this.selectedLeftMenu]?.subItems || [];
    },
  },
  methods: {
    navigateTo(path) {
      this.showSimpleMenu = false;
      this.showComplexMenu = false;
      this.showAboutMenu = false; // 关闭关于我们下拉菜单
      this.showQuickNav = false; // 关闭菜单
      this.$router.push(path);
    },
    handlePathTo(path) {
      window.open(path, "_blank");

    },
    selectLeftMenu(index) {
      this.selectedLeftMenu = index;
    },
    updateActiveMenu() {
      this.activeMenu = this.$route.path;
      // 根据当前路径匹配左侧菜单
      this.leftMenuItems.forEach((item, index) => {
        if (item.subItems.some(sub => sub.path === this.$route.path)) {
          this.selectedLeftMenu = index;
        }
      });
    },
    toggleQuickNav() {
      this.showQuickNav = !this.showQuickNav;
    },
  },
  watch: {
    $route() {
      this.updateActiveMenu();
    },
  },
  mounted() {
    this.updateActiveMenu(); // 初始化选中当前路由的菜单项
  },
};
</script>

<style scoped lang="less">
/* 导航栏容器 */
.nav-container {
  width: 100%;
  height: 64px;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001; /* 确保它在最上层 */

  .logoWrap {
    float: left;
    margin-left: 160px;
    text-align: center;

    img {
      position: relative;
      top: 22px;
    }
  }
}

/* 导航栏 */
.nav-bar {
  display: flex;
  align-items: center;
  padding: 0px 20px;
  font-size: 14px;
  position: relative;
  height: 100%;
}

/* 导航项 */
.nav-item {
  position: relative;
  padding: 21px 20px;
  cursor: pointer;
  color: #333;
  transition: color 0.3s, border-bottom 0.3s;
  font-family: "PingFang SC";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */

  .quick-nav-icon {
    position: relative;
    top: 3px;
  }
}

/* 选中的一级菜单 */
.nav-item.active {
  color: #d7000f;
}

.nav-item.active::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 3px;
  background-color: #d7000f;
}

/* 悬停变色 */
.nav-item:hover {
  color: #d7000f;
}

/* 下拉菜单 */
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: -40px;
  background: #fff;
  border: 1px solid #ddd;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

/* 简单下拉菜单 */
.simple-menu {
  width: 160px;
  display: flex;
  flex-direction: column;
}

.simple-menu .menu-item {
  padding: 10px;
  cursor: pointer;
  transition: background 0.3s;
  color: #222;
  font-family: "PingFang SC";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 285.714% */
  text-align: center;
}

.simple-menu .menu-item:hover {
  color: #d7000f;
}

/* 选中的简单菜单项 */
.simple-menu .menu-item.selected {
  background: white;
  color: #d7000f;
}

/* 复杂下拉菜单 */
.complex-menu {
  display: flex;
  width: 1885px;
  left: -459px !important;

  .left-menu {
    width: 530px;
    overflow: hidden;

    .left-menu-container {
      width: 60%;
      padding: 40px 0px 40px 50px;
      float: right;
      text-align: left;
      border-right: 1px solid #CCC;

      .left-menu-item {
        margin-top: 9px;
        color: #333;
        font-family: "PingFang SC";
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 24px;

        &:hover {
          color: #d7000f;
        }
      }
    }
  }

  .right-menu {
    display: flex;
    justify-content: flex-start;
    width: calc(100% - 430px);
    padding: 40px 40px 40px 60px;

    .right-menu-item {
      text-align: left;
      width: 35%;
      //padding: 10px;
      cursor: pointer;
      padding-right: 60px;
      transition: background 0.3s;
      /* 右侧菜单项标题 */
      &.active{
        .menu-item-title {
          color: #d7000f;
        }
      }
      &:hover{
        .menu-item-title {
          color: #d7000f;
        }
      }

      .menu-item-title {
        margin-bottom: 5px;
        color: #333;
        font-family: "PingFang SC";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 22.5px; /* 140.625% */
      }

      /* 右侧菜单项描述 */
      .menu-item-description {
        font-size: 14px;
        color: #666;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* 限制显示两行 */
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis; /* 超出部分显示省略号 */
        line-height: 24px; /* 171.429% */
      }
    }
  }
}

/* 左侧菜单 */
.left-menu-item.active {
  color: #d7000f !important;
}

/* 右侧快捷导航 */
.navigateWrap {
  position: absolute;
  right: 0;
  top: 0;
  background: white;
  display: flex;
  align-items: center;
  width: 420px;
  height: 64px;

  .nav-item {
    margin-right: 20px;
    cursor: pointer;
    width: 100px;

    &:first-child {
      font-weight: unset;
      position: relative;

      &:after {
        content: "";
        border-right: 1px solid rgba(0, 0, 0, 0.25);
        height: 13px;
        position: absolute;
        right: -14px;
        top: 25px;
      }
    }
  }

  .nav-auth {
    margin-left: auto;
    color: white;
    background: #D7000F;
    width: 100px;
    height: 64px;
    line-height: 64px;
    cursor: pointer;
    text-align: center;
    padding: 0 20px;
  }

  .quick-nav {
    position: absolute;
    top: 65px;
    right: 230px;
    background: white;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    padding: 15px;
    border-radius: 8px;
    min-width: 400px;
  }

  .quick-nav-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .quick-nav-header button {
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
  }

  .quick-nav-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  .quick-nav-buttons button {
    padding: 5px 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    cursor: pointer;
    background: #F2F3F5;
    transition: 0.3s;

  }

  .quick-nav-buttons button:hover {
    background: #eee;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s;
  }

  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
}


</style>
