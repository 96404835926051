const state = {
  device: 'desktop',
  bannerPath:''
}

const mutations = {
  TOGGLE_DEVICE: (state, device) => {
    state.device = device
  },
  SET_PARTNER: (state, partner) => {
    state.bannerPath= partner.link
  }
}

const actions = {
  toggleDevice({ commit }, device) {
    commit('TOGGLE_DEVICE', device)
  },
  setPartner({ commit }, partner) {
    commit('SET_PARTNER', partner)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
