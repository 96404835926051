<template>
  <div class="footer">
    <div class="footer-wrap">
      <div class="footer-content">
        <div class="footer-left">
          <div class="logo"><img src="@/assets/images/index/footer/LOGO.png"> 天人环境</div>
          <p>
            <i class="el-icon-phone-outline"></i> 电话：0532-80996050
          </p>
          <p>
            <i class="el-icon-message"></i> 邮箱：public@tianren.com
          </p>
          <p>
            <i class="el-icon-location-outline"></i> 地址：青岛市崂山区科苑纬四路30号天人大厦
          </p>
        </div>
        <div class="footer-right">
          <HoverPopover>
            <template #reference>
              <img
                  :src="require(`@/assets/images/index/footer/${hoverIndex === 0 ? redIcons.tiktok : icons.tiktok}.svg`)"
                  class="icon"
                  @mouseover="hoverIndex = 0"
                  @mouseleave="hoverIndex = -1"
              />
            </template>
            <img src="@/assets/images/index/footer/img_foot_douyin.png" class="qrcode" />
          </HoverPopover>

          <HoverPopover>
            <template #reference>
              <img
                  :src="require(`@/assets/images/index/footer/${hoverIndex === 1 ? redIcons.wechat : icons.wechat}.svg`)"
                  class="icon"
                  @mouseover="hoverIndex = 1"
                  @mouseleave="hoverIndex = -1"
              />
            </template>
            <img src="@/assets/images/index/footer/img_foot_wechat.png" class="qrcode" />
          </HoverPopover>

          <a @click="goToPage">
            <img
                :src="require(`@/assets/images/index/footer/${hoverIndex === 2 ? redIcons.link : icons.link}.svg`)"
                class="icon"
                @mouseover="hoverIndex = 2"
                @mouseleave="hoverIndex = -1"
            />
          </a>
        </div>
      </div>
      <div class="footer-bottom">
        ©2024Tianren　 鲁ICP备15022771号-1  公安备案号37021202001173
      </div>
    </div>
  </div>
</template>

<script>
import HoverPopover from './HoverPopover.vue'
export default {
  data() {
    return {
      hoverIndex: -1,
      icons: {
        tiktok: 'icon_dy',
        wechat: 'icon_weixin',
        link: 'icon_khj',
      },
      redIcons: {
        tiktok: 'icon_dy_a',
        wechat: 'icon_weixin_a',
        link: 'icon_khj_a',
      },
    };
  },
  components: {
    HoverPopover,
  },
  methods: {
    goToPage() {
      window.open("https://www.eiiplat.com/replay", "_blank");
    },
  },
};
</script>

<style lang="less" scoped>
.footer {
  background: #1d1f26;
  color: #fff;
  padding: 60px 0px 20px 0;
  font-size: 14px;
  width: 100%;
}

.footer-wrap{
  width: 1280px;
  margin: 0 auto;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.footer-left {
  line-height: 1.8;
  text-align: left;
}

.footer-left .logo {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  img{
    position: relative;
    margin-right: 10px;
    top: 2px;
  }
}

.footer-right {
  display: flex;
  gap: 15px;
}

.icon {
  width: 36px;
  height: 36px;
  cursor: pointer;
  transition: all 0.3s;
}

.qrcode {
  width: 120px;
  height: 120px;
}

.footer-bottom {
  text-align: center;
  margin-top: 10px;
  font-size: 12px;
  opacity: 0.7;
}
</style>
