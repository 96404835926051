<template>
  <div class="home-wrap">
    <!-- 1.轮播   -->
    <div class="swiper-container" style="width: 100%; margin: 0 auto;">
      <swiper :options="swiperOption">
        <swiper-slide v-for="(item, index) in slides" :key="index" :style="{ backgroundImage: `url(${item.image})` }">
          <div class="slide-content">
            <!-- 左侧文本部分 -->
            <div class="text-container">
              <h2>{{ item.title }}</h2>
              <p>{{ item.description }}</p>
              <button class="btn" @click="handleConsult">立即咨询</button>
            </div>
          </div>
        </swiper-slide>

        <!-- 轮播导航 -->
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
    <!-- 2.卡片   -->
    <div class="card-container">
      <div v-for="(item, index) in items" :key="index" class="card" @click="handlePathTo(item.path)">
        <div class="icon">
          <img v-lazy="item.icon" alt="icon"/>
          <h3 :class="{'highlight': index === items.length - 1}">{{ item.title }}</h3>
        </div>
        <p>{{ item.description }}</p>
      </div>
    </div>
    <!--  3.厌氧智控运维平台  -->
    <div class="platform-container ">
      <div class="custom-title-wrapper">
        <div class="custom-title">工信部首批认可的E平台支撑下的</div>
        <div class="custom-subtitle-wrapper">
          <img src="@/assets/images/index/title_line_left.png" class="custom-line">
          <div class="custom-subtitle" style="padding: 0 106px;">
            智控运维平台技术体系
          </div>
          <img src="@/assets/images/index/title_line_right.png" class="custom-line">
        </div>
      </div>
      <div class="img-wrap">
        <img v-lazy="require('@/assets/images/index/anaerobicAccusationPlatform.png')" alt="">
      </div>
    </div>
    <!--  4.权威认证  -->
    <div class="authoritativeCertification-wrap">
      <img v-lazy="require('@/assets/images/index/AuthoritativeCertification.png')" alt="">
    </div>
    <!--  5.解决方案  -->
    <div class="solution-wrap">
      <div class="custom-title-wrapper">
        <div class="custom-title">与行业龙头携手实践</div>
        <div class="custom-subtitle-wrapper">
          <img src="@/assets/images/index/title_line_left.png" class="custom-line">
          <div class="custom-subtitle">
            提供多行业、多场景的解决方案
          </div>
          <img src="@/assets/images/index/title_line_right.png" class="custom-line">
        </div>
      </div>

      <div class="solution-item">
        <div class="carousel-container">
          <!-- 顶部按钮 -->
          <div class="nav-buttons">
            <button
                v-for="(item, index) in carouselData"
                :key="index"
                :class="{ active: currentIndex === index }"
                @click="changeSlide(index)"
            >
              <img v-if="currentIndex === index"
                   :src="require(`@/assets/images/index/solution/${item.iconPath}.svg`)"/>
              <img v-else :src="require(`@/assets/images/index/solution/${item.iconRedPath}.svg`)"/>
              {{ item.title }}
            </button>
          </div>

          <!-- 轮播内容 -->
          <div class="carousel-content">
            <div class="left-wrap">
              <div class="left-container">
                <h2>{{ currentSlide.title }}</h2>
                <p>{{ currentSlide.description }}</p>
                <div class="buttons">
                  <button class="red-button" @click="handleConsult">立即咨询</button>
                  <button class="black-button" @click="handlePathTo(carouselData[currentIndex].path)">查看详情</button>
                </div>
              </div>
            </div>
            <div class="right">
              <img :src="currentSlide.image" alt="展示图"/>
            </div>
          </div>

        </div>
      </div>
    </div>
    <!--  6."三共"新模式  -->
    <div class="model-container">
      <!-- 标题部分 -->
      <div class="custom-title-wrapper">
        <div class="custom-title alone-title">“三共”新模式</div>
        <div class="custom-subtitle-wrapper">
          <img src="@/assets/images/index/title_line_left.png" class="custom-line">
          <div class="custom-subtitle alone-subtitle">
            资源共享 - 降本增效 - 拓展创新 - 行业担当 - 协同共生
          </div>
          <img src="@/assets/images/index/title_line_right.png" class="custom-line">
        </div>
      </div>

      <!-- 介绍部分 -->
      <p class="descTitle">近期价值：协同发展成为细分行业头部企业。</p>
      <p class="descTitle">远期价值：进入环境网络生态系统，实现可持续发展，实现共赢。</p>

      <p class="desc">
        以“三共”（共建、共推、共营）新模式，即共同建设平台，共同推广平台、共同利用平台开展运维服务业务，多方建立紧密的战略合作关系，发挥各自优势，共同推动环境产业数字化化转型升级，打造基于环境产业互联网平台国际领先的“三共”生态系统，实现共赢目标。</p>

      <!-- 两个主卡片 -->
      <div class="card-wrap">
        <img src="@/assets/images/index/newModel/model_card.png" alt="">
      </div>

      <!-- 盈利分配标准 -->
      <h3 class="section-title">三共盈利分配标准</h3>
      <p class="desc">在共营阶段：平台建设者为主体，按照市场价收取服务费，成立合资项目公司，推荐股份结构：</p>
      <p class="desc">若是“三共”伙伴，大平台建设主体：某细分领域（优势技术点）平台建设主体=3：7，同比例出资注册公司； </p>
      <p class="desc">若有第三方（共推+共营伙伴或投资方），“三共”方：第三方=6：4或7：3， </p>
      <p class="desc">“三共”方技术入股（对前期技术开发投入的认可），第三方资金入股。 </p>
      <p class="desc">“三共”方内部股份分配：大平台建设主体：某细分领域（优势技术点）平台建设主体=3：7。 </p>
      <p class="desc" style="margin-bottom: 40px">项目公司资本金额度，主要依据项目前期投入比例投资回收期等比例分配。</p>
      <p class="desc">
        按照投入产出对应和投资回收期的一般规律，以及长期共赢（包括用户和所有合作方）的总指导思想或经营理念，来平衡各参与方的利益分配。统一运维成本和效益核算方法标准，按照人/时计算，项目公司独立核算，按照股份比例分配利润，基础设施成本统一分摊，平台功能拓展和大数据挖掘收益与用户共享，提前达成共识，及时完善“三共”协议。</p>

      <!-- 了解更多按钮 -->
      <button type="primary" class="more-btn" @click="handlePathTo('/Sankyo')">了解更多</button>
    </div>
  </div>

</template>

<script>
import {swiper, swiperSlide} from "vue-awesome-swiper";
import "swiper/css/swiper.css"; // 确保正确引入 swiper 样式

export default {
  components: {swiper, swiperSlide},
  computed: {
    currentSlide() {
      return this.carouselData[this.currentIndex];
    },
  },
  data() {
    return {
      // 1.轮播
      swiperOption: {
        loop: true, // 循环播放
        autoplay: {
          delay: 5000, // 3秒切换
          disableOnInteraction: false, // 用户操作后仍然自动播放
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          bulletClass: "my-bullet", // 自定义分页样式
          bulletActiveClass: "my-bullet-active", // 选中样式
        },
      },
      slides: [
        {
          title: "平台化运维",
          description:
              "通过智控运维平台对生产运营的全过程平台化运维，帮助企业降本增效10%以上，平台化运维持续优化，功能迭代，通过增效模型算法实现降耗、减员、降低设备成本等，增效增长可实现每年2%-5%，综合增效最高可实现30%以上。",
          image: require("@/assets/images/index/home_slide_1.png"), // 图片路径
        },
        {
          title: "设备智能化升级",
          description:
              "通过设备智能化升级，利用大数据分析算法，用于业务管理和生产管控过程的工艺优化，为运营决策赋能，实现设备扩容30%以上，降本增效不低于20%。",
          image: require("@/assets/images/index/home_slide_2.png"),
        }
      ],
      // 2.卡片
      items: [
        {
          title: "智控运维平台",
          description: "实时采集分析生产数据，构建智能优化模型，实现降本增效。",
          icon: require('@/assets/images/index/card/icon_top_01.png'),
          path: '/core/IntelligentControlPlatform'
        },
        {
          title: "集团管控",
          description: "统一门户集成决策、管控、安防系统，依托数据中台支撑经营分析与决策。",
          icon: require('@/assets/images/index/card/icon_top_02.png'),
          path: '/core/GroupControl'
        },
        {
          title: "全产业链可追溯",
          description: "废弃物全产业链追溯，产品全生命周期管理。",
          icon: require('@/assets/images/index/card/icon_top_03.png'),
          path: '/core/Traceability'
        },
        {
          title: "设备智能化升级",
          description: "老旧设施升级，强化环保配置，满足地方环境友好需求。",
          icon: require('@/assets/images/index/card/icon_top_05.png'),
          path: '/core/EquipmentUpgrade'
        },
        {
          title: "自控集成",
          description: "建设全厂区域控制系统，实现设备100%线上自动化运行。",
          icon: require('@/assets/images/index/card/icon_top_04.png'),
          path: '/core/AutonomousIntegration'
        }
      ],
      // 5.解决方案
      currentIndex: 0,
      carouselData: [
        {
          title: "智控运维平台",
          description: "通过智控运维平台对生产运营的全过程平台化运维，帮助企业降本增效10%以上，平台化运维持续优化，功能迭代，通过增效模型算法实现降耗、减员、降低设备成本等，增效增长可实现每年2%-5%，综合增效最高可实现30%以上。",
          image: require("@/assets/images/index/solution/image (3).png"),
          iconPath: "ywpt",
          iconRedPath: "ywptRed",
          path: '/core/IntelligentControlPlatform'
        },
        {
          title: "集团管控",
          description: "打破数据孤岛，通过多网协同，提升集团统管效率最高50%以上，实现企业综合降本增效10%左右。",
          image: require("@/assets/images/index/solution/image (4).png"),
          iconPath: "jtgk",
          iconRedPath: "jtgkRed",
          path: '/core/GroupControl'

        },
        {
          title: "全产业链可追溯",
          description: "全产业链可追溯平台是基于数字化技术和标识技术，覆盖生产端、流通端、消费端及回收端，通过数据互联互通，精准追踪产品从产生到再生的全生命周期数据。提升供应链协同效率20%以上，助力企业综合降本增效10%以上。",
          image: require("@/assets/images/index/solution/image (5).png"),
          iconPath: "zs",
          iconRedPath: "zsRed",
          path: '/core/Traceability'

        },
        {
          title: "设备智能化升级",
          description: "通过设备智能化升级，利用大数据分析算法，用于业务管理和生产管控过程的工艺优化，为运营决策赋能，实现设备扩容30%以上，降本增效不低于20%。",
          image: require("@/assets/images/index/solution/image (7).png"),
          iconPath: "zkjc",
          iconRedPath: "zkjcRed",
          path: '/core/EquipmentUpgrade'

        },
        {
          title: "自控集成",
          description: "自控集成，赋能生产企业实现生产流程自动化、数据采集实时化、综合调度智能化。",
          image: require("@/assets/images/index/solution/image (6).png"),
          iconPath: "sbsj",
          iconRedPath: "sbsjRed",
          path: '/core/AutonomousIntegration'

        },
      ],
      showScrollTop: false, // 是否显示返回顶部按钮
    };
  },
  methods: {
    changeSlide(index) {
      this.currentIndex = index;
    },
    handlePathTo(path) {
      this.$router.push(path);
    },
    handleConsult() {
      let element = document.getElementById("ukefu-point");

      // 手动触发 onclick 事件
      if (element && element.onclick) {
        element.onclick();
      }
    }
  },
};
</script>

<style lang="less" scoped>
/* 轮播容器 */
.swiper-container {
  width: 100%;
  height: calc(100vh - 84px);
  /* 轮播项布局 */

  .swiper-slide {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center; /* 确保文本垂直居中 */
  }

  /* 文字内容区域 */

  .slide-content {
    width: 100%;
    display: flex;
    justify-content: flex-start; /* 让文字靠左 */
    align-items: center;
    height: 100%;
  }

  /* 分页容器 */

  .swiper-pagination {
    position: absolute;
    bottom: 140px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
  }

  /* 未选中的分页条 */

  /deep/ .my-bullet {
    width: 50px; /* 线条宽度 */
    height: 6px; /* 线条高度 */
    background: rgba(0, 0, 0, 0.10);
    margin: 0 5px; /* 线条间距 */
    transition: background-color 0.2s ease;
    cursor: pointer;
  }

  /* 选中的分页条 */

  /deep/ .my-bullet-active {
    background-color: #D7000F !important; /* 选中时变红色 */
  }

  /* 左侧文本 */

  .text-container {
    width: 35%;
    text-align: left;
    padding-left: 320px;
    color: #222222; /* 让文字在深色背景上更清晰 */
  }

  .text-container h2 {
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 10px;
    margin-top: -28px;
  }

  .text-container p {
    font-size: 20px;
    line-height: 32px;
  }

  .btn {
    background: #D7000F;
    color: white;
    border: none;
    font-size: 16px;
    display: flex;
    height: 44px;
    min-width: 120px;
    padding: 1px 20px;
    justify-content: center;
    align-items: center;
    gap: 5px;
    border-radius: unset !important;
    margin-top: 48px;
  }

  /* 右侧图片部分 */

  .image-container {
    width: 50%;
    display: flex;
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
  }

  .image-container img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
}

/* 公共标题样式 */
.custom-title-wrapper {
  text-align: center;
}

.custom-title {
  color: #D7000F;
  font-family: "PingFang SC";
  font-size: 24px;
  font-weight: 600;
  margin: 0;
  margin-top: 60px;
}

.alone-title{
  color: #222;
  font-family: "PingFang SC";
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  margin: 0;
  margin-top: 60px;
  margin-bottom: 10px;
}

.custom-subtitle-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px; /* 调整横线和文本的间距 */
}

.custom-line {
  width: 105px; /* 根据图片调整合适的宽度 */
  height: auto; /* 保持原始比例 */
  position: relative;
  top: -33px;
}

.custom-subtitle {
  padding: 0 60px;
  color: #222;
  font-family: "PingFang SC";
  font-size: 32px;
  font-weight: 600;
  margin: 0;
  margin-top: 10px;
  margin-bottom: 40px;
}

.alone-subtitle{
  color: #D7000F;
  font-family: "PingFang SC";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  margin-bottom: 40px;
}

/* 卡片 */
.card-container {
  position: relative;
  top: -100px;
  display: flex;
  gap: 16px;
  justify-content: center;
  z-index: 20;

  .card {
    width: 220px;
    padding: 20px 20px 0 20px;
    border-radius: 4px;
    background: linear-gradient(180deg, #F3F5F8 0%, #FFF 100%);
    text-align: left;
    cursor: pointer;
    transition: all 0.2s ease-in-out; /* Add transition */
    border: 2px solid #FFF;
    background: linear-gradient(180deg, #F3F5F8 0%, #FFF 100%);
    box-shadow: 8px 8px 20px 0px rgba(55, 99, 170, 0.10);
  }

  .card:hover {
    border-radius: 4px;
    border: 2px solid #FFF;
    background: linear-gradient(180deg, #F3F5F8 0%, #FFF 100%);
    box-shadow: 0px 8px 24px 0px rgba(255, 7, 7, 0.04), 0px 5px 18px 0px rgba(255, 7, 7, 0.07);

    h3 {
      color: #D7000F;
      font-size: 18px;
    }
  }

  .icon img {
    width: 30px;
    height: 30px;
    position: relative;
    top: 5px;
  }

  .icon h3 {
    display: inline-block;
    color: #222;
    font-family: "PingFang SC";
    font-size: 18px;
    margin: 0 5px;
    transition: all 0.2s ease-in-out; /* Add transition */
  }

  p {
    font-size: 14px;
    color: #666;
    text-align: left;
    line-height: 24px;
  }

  .highlight {
    color: red;
  }
}

/* 厌氧智控运维平台 */
.platform-container {
  padding-bottom: 50px;
  margin-top: -100px;

  h3 {
    color: #D7000F;
    font-family: "PingFang SC";
    font-size: 24px;
    font-weight: 600;
    margin: 0;
    margin-top: 60px;
  }

  h1 {
    color: #222;
    font-family: "PingFang SC";
    font-size: 32px;
    font-weight: 600;
    margin: 0;
    margin-top: 10px;
    margin-bottom: 40px;
  }
}

/*权威认证*/
.authoritativeCertification-wrap {
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 (如果你也想垂直居中图片的话) */

  img {
    width: 100%;
  }
}

/* 解决方案 */
.solution-wrap {
  //padding-bottom: 100px;

  h3 {
    color: #D7000F;
    font-family: "PingFang SC";
    font-size: 24px;
    font-weight: 600;
    margin: 0;
    margin-top: 60px;
  }

  h1 {
    color: #222;
    font-family: "PingFang SC";
    font-size: 32px;
    font-weight: 600;
    margin: 0;
    margin-top: 10px;
    margin-bottom: 40px;
  }

  // 轮播容器
  .carousel-container {
    width: 100%;
    margin: auto;
  }

  .nav-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 40px;
  }

  .nav-buttons button {
    display: flex;
    align-items: center;
    background: #fff;
    padding: 10px 32px;
    cursor: pointer;
    transition: 0.2s;
    color: black;
    border-radius: 8px;
    border: 2px solid #FFF;
    background: linear-gradient(180deg, #F3F5F8 0%, #FFF 100%);
    box-shadow: 8px 8px 20px 0px rgba(55, 99, 170, 0.10);
    font-weight: bold;

    img {
      margin-right: 12px;
    }
  }

  .nav-buttons button.active {
    color: white;
    border-radius: 8px;
    border: 2px solid #FFF;
    background: linear-gradient(232deg, rgba(215, 0, 15, 0.70) 3.81%, #D7000F 96.22%);
    box-shadow: 8px 8px 20px 0px rgba(170, 59, 55, 0.20);
  }

  .nav-buttons .icon.active {
    color: red;
  }

  .carousel-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #F5F7FA;
  }

  .left-wrap {
    flex: 1;
    background-color: #F5F7FA;
    height: 540px;
    position: relative;

    .left-container {
      width: 560px;
      position: absolute;
      right: 80px;
      top: 160px;
      text-align: left;

      h2 {
        color: #222;
        font-family: "PingFang SC";
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-top: 0;
      }

      p {
        color: #595959;
        text-align: justify;
        font-family: "PingFang SC";
        font-size: 16px;
        font-style: normal;
        line-height: 28px; /* 175% */
      }

      .buttons {
        margin-top: 60px;

        button {
          height: 44px;
          min-width: 120px;
          padding: 1px 20px;
          gap: 5px;
          border-radius: unset;
          cursor: pointer;
          border: none;
        }
      }
    }


  }

  .red-button {
    background: #D7000F;
    color: white;
  }

  .black-button {
    background: black;
    color: white;
    margin-left: 10px;
  }

  .right {
    flex: 1;
    text-align: center;
  }

  .right img {
    max-width: 100%;
  }
}

/* 三共新模式*/
.model-container {
  width: 1280px;
  margin: 0 auto;
  text-align: left;

  p {
    margin: 0;
  }

  .title {
    color: #222;
    font-family: "PingFang SC";
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
    margin: 0;
    margin-top: 60px;
    margin-bottom: 10px;
  }

  .subtitle {
    color: #D7000F;
    font-family: "PingFang SC";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
    margin-bottom: 40px;
  }

  .descTitle {
    color: #222;
    font-family: "PingFang SC";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 16px 0;
  }

  .desc {
    color: #595959;
    font-family: "PingFang SC";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 175% */
  }

  .card-wrap {
    margin: 40px 0;
    height: auto;
    overflow: hidden;

    img {
      width: 100%;
    }
  }

  .section-title {
    color: #222;
    /* 二级标题 */
    font-family: "PingFang SC";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .more-btn {
    display: inline-block;
    margin-top: 40px;
    margin-bottom: 60px;
    width: 140px;
    height: 44px;
    min-width: 120px;
    padding: 1px 20px;
    background: #D7000F;
    color: #FFF;
    text-align: center;
    font-family: "PingFang SC";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 175% */
    border: unset;
    cursor: pointer;
  }
}
</style>
