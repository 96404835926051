<template>
  <div class="hover-popover" @mouseenter="show = true" @mouseleave="show = false">
    <slot name="reference"></slot>
    <transition name="fade">
      <div v-if="show" class="popover-content">
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
    };
  },
};
</script>

<style scoped>
.hover-popover {
  position: relative;
  display: inline-block;
}

.popover-content {
  position: absolute;
  bottom: 120%; /* 让它出现在上方 */
  left: 50%;
  transform: translateX(-50%);
  background: #fff;
  padding: 2px 2px 0 2px;
  //border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  white-space: nowrap;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
