<template>
  <div style="position: relative" id="app">
    <div id="app-container">
      <Header/>
    </div>
    <div>
      <div class="app-main-container">
        <router-view/>
      </div>
      <Footer/>
    </div>
    <!-- 返回顶部按钮（使用图片） -->
    <div class="scroll-top" @click="scrollToTop" v-if="showScrollTop">
      <img
          src="@/assets/images/index/scrollToTop.png"
      />
    </div>

  </div>

</template>

<script>
import Header from "@/components/layouts/Header.vue";
import Footer from "@/components/layouts/Footer.vue";

export default {
  data() {
    return {
      showScrollTop: false, // 是否显示返回顶部按钮
      scaleStyle: {},
    };
  },
  components: {
    Header, Footer
  },
  mounted() {
    // 在 App.vue 组件中触发 prerender 事件
    document.dispatchEvent(new Event('render-event'));
    /*
    * 设置页面随着分辨率缩放
    * */
    this.setScale();
    window.addEventListener("resize", this.setScale);

    window.addEventListener("scroll", this.handleScroll);

  },
  beforeDestroy() {
    window.removeEventListener("resize", this.setScale);
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    setScale() {
      const designWidth = 1920; // 设计稿宽度
      const windowWidth = window.innerWidth;
      const scale = Math.min(windowWidth / designWidth);

      this.scaleStyle = {
        transform: `scale(${scale})`,
        transformOrigin: "0 0",
        width: designWidth + "px",
      };
    },
    scrollToTop() {
      window.scrollTo({top: 0, behavior: "smooth"}); // 平滑滚动到顶部
    },
    handleScroll() {
      this.showScrollTop = window.scrollY > 300; // 滚动超过 300px 才显示按钮
    }
  },
};
</script>


<style lang="less">
#app {
  font-family: "PingFang SC";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #222;
  width: 100%;

}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.scroll-top {
  position: fixed;
  bottom: 60px;
  right: 30px;
  cursor: pointer;
  transition: all 0.3s;
  opacity: 0.8;
  width: 54px;
  height: 54px;
  padding: 5px;
  border-radius: 100px;
  border: 2px solid #FFF;
  background: linear-gradient(180deg, #F3F5F8 0%, #FFF 100%);
  box-shadow: 8px 8px 20px 0px rgba(55, 99, 170, 0.10), 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
  text-align: center;
  /** 使用 Flexbox 让图片垂直 & 水平居中 */
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 50%;
  }
}

.scroll-top:hover {
  opacity: 1;
  transform: scale(1.1); /* 悬浮时稍微放大 */
}
</style>
