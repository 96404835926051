/*
 * @Author: wuzhen wz0309@163.com
 * @Date: 2023-11-22 09:07:35
 * @LastEditors: lwk
 * @LastEditTime: 2024-02-27 16:06:49
 * @FilePath: \eiiplat_front\src\utils\request.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import axios from "axios";
import { Message } from "element-ui";
import store from "@/store";
import router from "@/router";
const baseURL = "/api";
// import { throttle } from "lodash";
// console.log(throttle, 'throttle');
// create an axios instance
const service = axios.create({
  baseURL: baseURL, // url = base url + request url
  timeout: 60000 // request timeout
});
let isReFreshPadding = true;

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    config.headers = {
      token: localStorage.getItem("usertoken")
    };
    return config;
  },
  error => {
    // do something with request error
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data;
    // 处理文件流文件
    if(res instanceof Blob||response.headers['content-type'].includes('application/octet-stream')){
      return response;
    }
    if ((!Array.isArray(res))&&res.code != 0&& !res instanceof Blob) {
      console.log('错误');
      Message({
        message: res.msg || "Error",
        type: "error",
        duration: 3 * 1000
      });

      return Promise.reject("error");
    } else {
      return res;
    }
  },
  error => {
    if (error.response.status === 401) {
      if (isReFreshPadding) {
        isReFreshPadding = false

        Message({
          message: "登录信息过期，请重新登录",
          type: "warning",
          duration: 3 * 1000
        });
        store.dispatch("logout");
        router.replace("/login");
        let timeout = setTimeout(() => {
          isReFreshPadding = true;
          clearTimeout(timeout);
        }, 1500)
      }


    } else {
      Message({
        message: error.message || error.msg,
        type: "error",
        duration: 3 * 1000
      });
    }
    console.log("err" + error); // for debug

    return Promise.reject(error);
  }
);

export default service;
