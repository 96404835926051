/*
 * @Author: lwk
 * @Date: 2024-01-24 08:49:13
 * @LastEditors: lwk
 * @LastEditTime: 2024-02-06 17:10:56
 * @Description: 请填写简介
 */
import {  getMsgRead  } from '@/api/message'
const state = {
  token: localStorage.getItem("usertoken"),
  userName: localStorage.getItem("userName"),
  userId: localStorage.getItem("userId"),
  badgeVal:''
};

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_NAME: (state, name) => {
    state.userName = name;
  },
  SET_USERID: (state, userId) => {
    state.userId = userId;
  },
  SET_BADGEVAL: (state, badgeVal) => {
    state.badgeVal = badgeVal;
  },

};

const actions = {
  // user login
  login({ commit }, userInfo) {
    let { username, password, type } = userInfo;
    return new Promise((resolve, reject) => {
      login({ username, password, type })
        .then(response => {
          let { data } = response;
          data = JSON.parse(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo(state.token)
        .then(response => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 获取未读信息
  getbadgeVal(context) {
    let readParams = {
      read_flag: 0
    }
    return new Promise((resolve, reject) => {
      getMsgRead(readParams).then(res => {
        // context.commit.SET_BADGEVAL(res.data)
        context.commit('SET_BADGEVAL',res.data)
      }).catch(error => {
          reject(error);
        });
    });

  },

  // user logout
  logout({ commit, state }) {
    return new Promise((resolve, reject) => {
      commit("SET_TOKEN", "");
      commit("SET_NAME", "");
      localStorage.clear();
      resolve();
    });
  }
};

export default {
  // namespaced: true,
  state,
  mutations,
  actions
};
